.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.logo-header {
  background-color: #ececf1;
  border-radius: 20px;
  margin: 20px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.dropzone {
  min-height: 120px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* upload file */
.p-button .uploadButton {
  display: none !important;
}

/* upload file */
/* login */

.line-divider {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.line-divider .line {
  flex: 1;
  border-top: 1px solid #ccc;
}

.line-divider span {
  padding: 0 10px;
  font-size: 1rem;
  color: #666;
}

.create-account-btn {
  background-color: white;
  border: 1px solid #ccc;
  color: #1d4ed8;
  padding: 10px 20px;
  border-radius: 5px;
  /* font-weight: bold; */
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
}

/* login */

/* AuthLayout */

.auth-layout {
  display: flex;
  overflow-y: hidden;
  min-height: 100vh;
}

.left-side {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* Centers the logo-container vertically */
  align-items: center;
  position: relative;
  height: 100vh;
  /* Makes the left side take full height */
  padding: 20px;
  /* Add padding as necessary */
}

/* used to hide buttons that should not be accessible by pharmacy user status */
.status-hidden {
  visibility: hidden !important;
}

.subsidiary-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 50%;
  height: 5px;
  margin-bottom: 20px;
  color: white !important;
}

.subsidiary-container p {
  filter: brightness(0) invert(1);
  /* This will make the logo white */
  font-size: 20px !important;
  margin: 0px;
  font-size: 16px;
  /* Set a fixed font size */
  white-space: nowrap;
  /* Prevent the text from wrapping */
  flex-shrink: 0;
  /* Prevent the <p> from shrinking */
  color: white !important;
}

@media (max-width: 768px) {
  .auth-layout {
    flex-direction: column;
  }

  .auth-layout .left-side {
    display: none;
    /* Hide left side on mobile */
  }

  .auth-layout .right-side {
    margin-left: 0;
    padding-left: 0;
  }
}

@media (min-width: 769px) {
  .auth-layout .left-side {
    display: flex;
    width: 50%;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    justify-content: space-between;
    align-items: center;
    background: #f3f4f6 url('./assets/images/registerbg.jpg') no-repeat center
      center/cover;
    padding: 1.25rem;
    overflow: hidden;
  }

  .auth-layout .right-side .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

    /* Ensures the content takes full height of the screen */
  }

  .auth-layout .right-side .card {
    width: 100%;
    max-width: 500px;
    /* Limits the width of the card */
    margin: 0 auto;
    /* Centers the card horizontally */
  }

  /* .auth-layout .left-side .overlay {
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.3);
  } */
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    /* Adjust overlay styling */
  }

  .auth-layout .left-side .logo-container {
    position: relative;

    /* height: 13rem !important; */
    padding: 16px 24px !important;
    display: flex;
    border-radius: 10px;
    background-color: white;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  /* .auth-layout .left-side .logo-container .logo {
    width: 16rem !important;
 
  } */

  .logo {
    max-width: 100%;
  }

  .logo-two {
    filter: brightness(0) invert(1);
    /* This will make the logo white */
    max-width: 50%;
  }

  .auth-layout .right-side {
    margin-left: 50%;
    padding-left: 2rem;
    flex: 1;
    overflow: hidden;
  }
}

.auth-layout .logo-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8rem;
  height: 8rem;
  margin: 1.25rem auto;
}

.loading-container {
  display: grid;
  place-content: center;
  min-height: 100vh;
  background-color: rgba(255, 255, 255, 0.3);
}

.loading-container.dark {
  background-color: rgba(6, 8, 24, 0.08);
}

.loading-container .spinner {
  width: 1rem;
  height: 1rem;
  margin-bottom: 2.5rem;
  display: inline-flex;
  border-radius: 50%;
  background-color: #1d4ed8;
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

@keyframes ping {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  75%,
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

/* AuthLayout */

/* advertising button */

.p-carousel .p-carousel-content .p-carousel-next {
  position: absolute;
  bottom: -17px;
  right: 86px; /* Adjust this value to control how far above the carousel the buttons should be */
  width: 24px; /* Increase the button size if necessary */
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff; /* You can adjust background or remove this line */
  border-radius: 8px; /* Makes the buttons circular */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); /* Optional shadow */
  z-index: 10;
  margin-bottom: 20px;
}
.p-carousel .p-carousel-content .p-carousel-prev {
  position: absolute;
  bottom: -17px;
  left: 77px; /* Adjust this value to control how far above the carousel the buttons should be */
  width: 24px; /* Increase the button size if necessary */
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff; /* You can adjust background or remove this line */
  border-radius: 8px; /* Makes the buttons circular */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); /* Optional shadow */
  z-index: 10;
  margin-bottom: 20px;
}
p.-carousel-indicators {
  display: flex !important;
  justify-content: center !important;
  gap: 5px !important;
}

.advertising-button {
  position: fixed !important;
  bottom: 50px !important;
  right: 37px !important;
  z-index: 1001 !important;
}

.advertising-button img {
  width: 24px !important;
  height: 24px !important;
}

.advertising-button-window {
  position: fixed;
  bottom: 120px;
  left: 26px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: calc(100vw - 40px);
  z-index: 1001;
  max-height: 500px;
  overflow-y: auto;
  border: 0px solid #ddd;
  background-color: transparent;
  padding: 10px;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .advertising-button-window {
    bottom: 90px !important;
  }

  .advertising-button {
    bottom: 20px !important;
  }
}

.advertising-button-window::-webkit-scrollbar {
  width: 8px;
}

.advertising-button-window::-webkit-scrollbar-track {
  filter: blur(10px);
  border-radius: 10px;
}

.advertising-button-window::-webkit-scrollbar-thumb {
  background-color: #c1bebe;
  border-radius: 10px;
  border: 0px solid #f1f1f1;
}

.advertising-button-window::-webkit-scrollbar-thumb:hover {
  background-color: #c0bbbb;
}

/* advertising button */

/*Banner */
@keyframes mdi-shake {
  0% {
    transform: rotate(0);
  }

  10% {
    transform: rotate(-20deg);
  }

  20% {
    transform: rotate(20deg);
  }

  30% {
    transform: rotate(-20deg);
  }

  40% {
    transform: rotate(20deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }

  /* Pause at the original position */
}

.mdi-shake {
  animation: mdi-shake 1s infinite;
  /* Complete cycle time includes shake + pause */
  transform-origin: center;
  font-size: 20px !important;
}

.profileCompletionBanner {
  padding: 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  background-color: #ff4500;
  color: white;
  padding: 10px 20px;
  transition: all 0.3s ease;
  position: relative;
  top: 70px;
}

body.vertical-collpsed .profileCompletionBanner {
  padding-left: 80px;
}

body:not(.vertical-collpsed) .profileCompletionBanner {
  padding-left: 240px;
}

.messageContainer {
  margin-left: 10px;
  display: flex;
  /* align-items: center; */
  gap: 10px;
}

.icon {
  font-size: 16px;
  width: 24px !important;
  height: 24px !important;
}

.message {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0px;
}

.actionContainer {
  display: flex;
  align-items: center;
}

.actionButton {
  margin-right: 10px;
}

.actionIcon {
  height: 20px;
}

@media (max-width: 768px) {
  body.vertical-collpsed .profileCompletionBanner,
  body:not(.vertical-collpsed) .profileCompletionBanner {
    padding-left: 10px;
    /* Reset padding for smaller screens */
  }

  .profileCompletionBanner {
    flex-direction: column;
    text-align: center;
    padding: 15px 10px;
    /* Adjust padding for smaller screens */
  }

  .messageContainer {
    margin-bottom: 10px;
    justify-content: center;
  }

  .actionContainer {
    justify-content: center;
  }
}

/*Banner */

/* orthoplex */
/* loading data table */
.p-datatable .p-datatable-loading-icon.p-icon {
  color: #616ed3;
}

.p-datatable-loading-overlay {
  background-color: #ffffff90 !important;
}

.card-background-color {
  background-color: #f8f8fa !important;
  border: 2px #ced4da solid !important;
  border-radius: 8px !important;
}

/* column filter */
.p-column-filter-remove-button {
  display: none;
}

.p-column-filter-constraint:nth-child(1) {
  padding-bottom: 0px !important;
  border: 0px !important;
}

.p-column-filter-constraint:nth-child(2) {
  padding-top: 8px !important;
  border: 0px !important;
}

/* disabled input */

.form-control:disabled {
  background-color: rgb(212, 208, 208) !important;
  color: rgb(165, 165, 165);
}
.disabledInput,
.disabledInput input {
  background-color: #e4e4e4;
  color: rgb(165, 165, 165);
  pointer-events: none;
}

/* hide clear button from calender */
.calender-clear-btn {
  display: none;
}

/* displace date picker modal so that the apply button can appear */
.p-datepicker {
  /* top: 200px !important; */
  z-index: 999999;
}

.css-165mck1 {
  width: 100% !important;
}

/* =========================================================== */
/* Sample container */
.main-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.check-background-page {
  width: 12rem;
  height: 12rem;
  background: linear-gradient(to bottom right, #63f297, #63f297);
  /* box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset; */
  transform: scale(0.84);
  border-radius: 50%;
  animation: animateContainer 0.75s ease-out forwards 0.75s;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.check-background-page svg {
  width: 65%;
  transform: translateY(0.25rem);
  stroke-dasharray: 80;
  stroke-dashoffset: 80;
  animation: animateCheck 0.35s forwards 1.25s ease-out;
}

.check-background {
  width: 9rem;
  height: 9rem;
  background: linear-gradient(to bottom right, #63f297, #63f297);
  /* box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset; */
  transform: scale(0.84);
  border-radius: 50%;
  animation: animateContainer 0.75s ease-out forwards 0.75s;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.check-background svg {
  width: 65%;
  transform: translateY(0.25rem);
  stroke-dasharray: 80;
  stroke-dashoffset: 80;
  animation: animateCheck 0.35s forwards 1.25s ease-out;
}

@keyframes animateContainer {
  0% {
    opacity: 0;
    transform: scale(0);
    box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }

  25% {
    opacity: 1;
    transform: scale(0.9);
    box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }

  43.75% {
    transform: scale(1.15);
    box-shadow: 0px 0px 0px 43.334px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }

  62.5% {
    transform: scale(1);
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 21.667px rgba(255, 255, 255, 0.25) inset;
  }

  81.25% {
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
  }

  100% {
    opacity: 1;
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
  }
}

@keyframes animateCheck {
  from {
    stroke-dashoffset: 80;
  }

  to {
    stroke-dashoffset: 0;
  }
}

/*System change */
.p-megamenu {
  background: none !important;
  border: 0;
}

.p-megamenu-panel {
  border-radius: 20px;
  overflow: hidden;
  color: #676d76 !important;
}

.p-megamenu-submenu-header,
.p-megamenu-col-2,
.p-megamenu-col-3,
.p-megamenu-col-4,
.p-megamenu-col-6,
.p-megamenu-col-12 {
  padding: 0 !important;
}

.p-menuitem-link .p-menuitem-text {
  color: #5b626b !important;
}

.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem
  > .p-menuitem-link:not(.p-disabled):hover {
  background: none !important;
}

.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: none !important;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:focus {
  box-shadow: none !important;
}

.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link
  .p-menuitem-text,
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover
  .p-menuitem-text,
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link
  .p-submenu-icon,
.p-megamenu
  .p-megamenu-root-list
  > .p-menuitem.p-menuitem-active
  > .p-menuitem-link:not(.p-disabled):hover
  .p-submenu-icon {
  color: #676d76 !important;
}

/* timePicker */
.css-1mtsuo7 {
  z-index: 10000000 !important;
}

.p-selectbutton .p-button.p-highlight {
  background: #626ed4 !important;
  border-color: #626ed4 !important;
  color: #ffffff;
}

.p-selectbutton .p-button {
  height: 40px !important;
  width: 182px !important;
  margin-bottom: 15px;
}

/* styles.css */
.p-datatable .gray-background {
  background-color: #f0f0f0 !important;
}

.p-menuitem-text {
  width: 90px !important;
}
.p-menuitem-link {
  padding: 8px 16px !important;
}
